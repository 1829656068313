
import { SignIn } from 'aws-amplify-react/lib/Auth';
import { AuthState} from '@aws-amplify/ui-components';
import { Auth } from "aws-amplify";
import "./login.css";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import fc from "../imgcomponents/fricongelados.png";
import fondo from "../imgcomponents/fondo-login.jpg";
import spiner from "../imgcomponents/cargando.gif";
import { Alertas } from '../componets/Alertas';

export class CustomSignIn extends SignIn {

  constructor(props){
    super(props);

    this.state={
      Username:"",
      Password:"",
      showPassword: false,
      modal: false,
      tipoalert: 0,
      view: false,
      mensaje: "",
    }

    this.signIn = this.signIn.bind(this);

    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleFormSubmission2 = this.handleFormSubmission2.bind(this);
    this.handleShowPasswordClick = this.handleShowPasswordClick.bind(this);
  }

  handleShowPasswordClick() {

    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }

  handleFormSubmission(evt) {
    evt?.preventDefault();
    if (evt.keyCode === 13) {
      // aquí va la lógica que queremos ejecutar
      this.setState({ IsLoading: true });
      this.signIn();
    }
  }
  handleFormSubmission2(evt) {

    if(this.state.Username==="" || this.state.Password===""){
      this.setState(
        {view: true, 
         tipoalert: 1, 
         mensaje: "valores Faltantes"
        }
      )
    }else{
      evt?.preventDefault();
      this?.signIn();
      this?.setState({ modal: true });
    }
  }
  

    async signIn() {
      const username = this.state.Username;
      const password = this.state.Password;
      try {
        await Auth.signIn(username, password);
        await this.props.SetAuthState(AuthState.SignedIn);
        localStorage.setItem('isAuthenticated', 'true');
     } catch (err) {
        this.setState({ modal: false });
      
        if (err.code === "UserNotConfirmedException") {
          this.setState({ error: "Login failed." });
          console.log("1");
        } else if (err.code === "NotAuthorizedException") {
          this.setState({ error: "Login failed." });
          console.log("2");
          //alert("El usuario o la contraseña no coinciden")
          this.setState(
            {view: true, 
             tipoalert: 1, 
             mensaje: "El usuario o la contraseña no coinciden"
            }
          )
        } else if (err.code === "UserNotFoundException") {
          this.setState({ error: "Login failed." });
          console.log("3");
  
        } else {
          this.setState({ error: "An error has occurred." });
          console.error(err);
  
        }
      }
    }

    actualizarEstado = (nuevoEstado) => {
      this.setState(nuevoEstado);
    }
  
    showComponent(){
      
      const { showPassword } = this.state;

      const texto = document.getElementById('texto');
    
      return (
        <>
        <Alertas view={this.state.view} tipoalert={this.state.tipoalert} mensaje={this.state.mensaje} actualizarEstado={this.actualizarEstado}></Alertas>
        <div className='container_login'>
          {
            this.state.modal?(
              <>
              <div id="contenedor">
                <div class="contenedor-loader">
                  <div class="loader1"></div>
                  <div class="loader2"></div>
                  <div class="loader3"></div>
                  <div class="loader4"></div>
                </div>
                <div class="cargando">Iniciando Sesión...</div>  
              </div>
              </>
            ):
            (
              <div className='grid-login'>
              <div className='formsesion'>
                <div className='logoempresa'>
                    <img src={fc}></img>
                </div>
                  
                <div className='camplogin'>
                
                < div className="div-lg1">
                  <p className='bienvenido'>Bienvenido de nuevo</p>
                  <p className='text-login'>Inicia sesión para continuar</p>
                </div>
                  
                <div className='grid-form-login'>
                  
                  <div className='form-user'>
                  <label className='label-usuario'>Usuario</label>
                  <input 
                    type={"text"}
                    className="input-usuario"
                    onChange={(e) =>
                      this.setState({Username: e.target.value})  
                    }  
                    placeholder="Escribe tu Usuario"
                  ></input>
                  </div>
                  <div className='form-pass'>
                    <div className='pass1grid'>
                   
                    <label className='label-contraseña'>Contraseña</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        //value={password}
                        placeholder="Contraseña"
                        onKeyUp={this.handleFormSubmission}
                        onChange={(e) =>
                          this.setState({ Password: e.target.value })
                        }
  
                      />
                    </div>
                    <div className='pass2grid'>
                    {showPassword ? (
                        <IconButton onClick={this.handleShowPasswordClick}>
                          <VisibilityIcon style={{ color: "#fff" }}/>
                        </IconButton>
                      ) : (
                        <IconButton onClick={this.handleShowPasswordClick}>
                          <VisibilityOffIcon style={{ color: "#fff" }}/>
                        </IconButton>
                      )}
                    </div> 
                  </div>
                  <div className=''>
                      <button className='boton-login' onClick={this.handleFormSubmission2}>
                        Iniciar Sesión
                      </button> 
                      
                      <button className='boton-registrarse' 
                        onClick={()=>this.props.SetAuthState(AuthState.SignUp)}>
                        Registrarse
                      </button>        
                      <a className='forgot-pass'
                        onClick={()=>this.props.SetAuthState(AuthState.ForgotPassword)}
                      >Olvidaste tu contraseña</a>
                    </div>
                </div>       
                </div>
              </div>
              <div className='imglogin'>
                <img src={fondo}></img>
              </div>
            </div>
            )
          }    
        </div>
      </>
    );
 }
}
export default CustomSignIn;
