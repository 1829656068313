import React, { Component } from "react";
import { AuthState } from "@aws-amplify/ui-components";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import fc from "../imgcomponents/fricongelados.png";
import fondo from "../imgcomponents/fondo-login.jpg";
import { Menu2 } from "./Menu2";
import "./index.css";

import { Navbar } from "./Navbar";
import { Formats } from "../pages/formats/Formats";

import { Procedures } from "../pages/procedures/Procedures";
import { Home } from "../pages/home";
import { Capacitacion } from "../pages/capacitacion/Videos";
import { Gsheetnotion } from "../pages/gsheets/Gsheetnotion";
import { SUBIRS3 } from "../pages/formats/subirs3";

export class index extends Component {

    constructor(props) {    
    super(props);  
    }
 
   render() {
  
        sessionStorage.setItem("getAccesTokenRefresh",this?.props?.User?.["signInUserSession"]?.["accessToken"]?.["jwtToken"]);
        sessionStorage.setItem("getIdTokenRefresh",this?.props?.User?.["signInUserSession"]?.["idToken"]?.["jwtToken"]);
       
        console.log(this?.props?.User);
        
        var permisosUsuario = this?.props?.User?.["attributes"]?.["custom:roles"];
        var permisoUsuarioReplace = permisosUsuario?.replace(/ /g, "");
        var permisos = permisoUsuarioReplace?.split(",");
       // console.log(permisos);

       console.log(this.props.AuthState===AuthState.SignedIn);

   if (this.props.AuthState === AuthState.SignedIn) {

  return (
   <>
    <div  id="tc">
          {this.props.User === null ? (
            <div> Loading User </div>
          ) : (
          <div className="container-dashboard">  
          
            <Menu2 SetAuthState={this.props.SetAuthState} ></Menu2>
            <section class="home">
            <Navbar props={this.props.User}></Navbar>
            <Router>
                <Switch> 
                   <Route path={"/"} exact component={Home}></Route>
                   <Route path={"/procedures"} exact component={Procedures}/>
                   <Route path={"/formats"} exact component={Formats}></Route>
                   <Route path={"/training"} exact component={Capacitacion}></Route>
                   <Route path={"/gsheets"} exact component={Gsheetnotion}></Route>
                   <Route path={"/subirs3"} exact component={SUBIRS3}></Route>
                   <Redirect to="/"></Redirect>
                </Switch>
            </Router>
            </section>     
          </div>
          )}
        </div>  
    </>
  );
}else{
    return (
        <>
        <div className='container_login'>
          <div className='grid-login'>
            <div className='formsesion'>
              
              <div className='logoempresa'>
                  <img src={fc} alt=""></img>
              </div>
                
              <div className='camplogin'>
              <br></br>
              < div className="div-lg1">
                <p className='bienvenido'>Bienvenido de nuevo</p>
                <p className='text-login'>Web Corporativa fricongelados</p>                
              </div>
              <br></br>
              <br></br>
              
              <div className="boton-ingresar">
                   <a
                     className="form-control btn btn-primary"
                      onClick={(e) => this.props.SetAuthState(AuthState.SignIn)}>
                     Ingresar
                   </a>
              </div>              
                
        
              </div>
            </div>
            <div className='imglogin'>
              <img src={fondo} alt=""></img>
            </div>
          </div>
        </div>
        </>
    );
  }
 }
}

export default index;