import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import fc from "../imgcomponents/fricongelados.png";
import fondo from "../imgcomponents/fondo-login.jpg";


export class ConfirmSignUp extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         Code : '',
         Username:'',
      }
  
      this.confirmSignUp = this.confirmSignUp.bind(this);
      this.handleFormSubmission = this.handleFormSubmission.bind(this);
      this.handleFormSubmission2=this.handleFormSubmission2.bind(this);
  
    }
  
    async resendConfirmationCode(){
      try {
        await Auth.resendSignUp(this.state.Username);
        // console.log('code resent successfully');
      } catch (err) {
          // console.log('error resending code: ', err);
      }
    }
  
    handleFormSubmission(evt) {
      evt.preventDefault();
      this.confirmSignUp();
    }

    handleFormSubmission2(evt){
      
      console.log(this.props);
      evt.preventDefault();
      this.renviarcodigo();
    }

    async renviarcodigo(){
      try {
        await Auth.resendSignUp(this.state.Username);
        console.log('Código reenviado exitosamente');
      } catch (err) {
        console.log('Error al reenviar código: ', err);
      }
    }
  
    async confirmSignUp() {
      const username = this.state.Username;
      const code = this.state.Code;
  
      try {
        await Auth.confirmSignUp(username, code);
        this.props.SetAuthState(AuthState.SignIn)
      } catch (error) {
          // console.log('error confirming sign up', error);
      }
    }
    
    
    render() {
      return (
        <div className='container_login'>
          <div className='grid-login'>
            <div className='formsesion'>
              <div className='logoempresa'>
                  <img src={fc}></img>
              </div>
                
              <div className='camplogin'>
              
              < div className="div-lg3">
                <p className='bienvenido'>Verifica tu correo</p>
              </div>
              <div className='grid-form-login'>
              <div className='form-user'>
                <label className='label-usuario'>Usuario</label>
                <input 
                  type={"text"}
                  className="input-usuario"
                  onChange={(e) =>
                    this.setState({Username: e.target.value})  
                  }  
                  placeholder="Escribe tu Usuario"
                ></input>
                </div>
                <div className='form-user'>
                <label className='label-usuario'>Código Verificación</label>
                <input 
                  type={"text"}
                  className="input-usuario"
                  onChange={(e) =>
                    this.setState({Code: e.target.value})  
                  }  
                  placeholder="Ingresa código de verificación"
                ></input>
                </div>
              
                
                <div className=''>
                    <button className='boton-login' 
                        onClick={this.handleFormSubmission}>
                      Verificar Cuenta
                    </button> 
                    
                    <button className='boton-registrarse' 
                      onClick={this.handleFormSubmission2}>
                      Reenviar Código
                    </button>        
                    <p className='forgot-pass'>Olvidaste tu contraseña</p>
                  </div>
              </div>
                
                 
                
              </div>
            </div>
            <div className='imglogin'>
              <img src={fondo}></img>
            </div>
          </div>
          </div>
        
      );
    }
  }
  
  export default ConfirmSignUp;
  