// import './style.css';
import { useState, useEffect } from 'react';
import "./videos.css";
import { data } from "./datavideos";
import { Reproductor } from './Reproductor';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';


export const Capacitacion = () => {


  useEffect(() => {
    nuevoarray();
  }, [])
  

  const [showcatalog, setshowcatalog]=useState(true);
  const [showsubcatalog, setshowsubcatalog]=useState(false);
  const [showvideos, setshowvideos]=useState(false);
  const [showplay, setshowplay]=useState(false);
  const [showlist, setshowlis]=useState(false);

  const [datasub, setdatasub]=useState([]);
  const [datavideos, setdatavideos]=useState([]);
  const [datasearchvideos, setdatasearchvideos]=useState([]);
  const [datasv, setdatasv]=useState([]);

  const [url, seturl]=useState("");
  const [videos, setvideos]=useState([]);


  const [nivel1, setnivel1]=useState("");
  const [nivel2, setnivel2]=useState("");
  const [nivel3, setnivel3]=useState("");


  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);


  const filtro1 = (item) =>{
    setshowcatalog(false);
    setshowsubcatalog(true);
    setshowvideos(false);
    setshowplay(false);
    setdatasub(item.sections);
    setnivel1(item.name);
  }
  const filtro2 = (item) =>{
    setshowcatalog(false);
    setshowsubcatalog(false);
    setshowvideos(true);
    setshowplay(false);
    setdatavideos(item.videos);
    setnivel2(item.name);
  }
  const filtro3 = (item, videos)=>{
    setshowcatalog(false);
    setshowsubcatalog(false);
    setshowvideos(false);
    setshowplay(true);
    setshowlis(false);
    setvideos(videos);
    seturl(item.url);
    setnivel3(item.titlevideo);
  }
  const filtro4 =(item)=>{
    console.log(item);
    nivelesacceso(item.titlevideo);
    setshowcatalog(false);
    setshowsubcatalog(false);
    setshowvideos(false);
    setshowplay(true);
    setshowlis(false);
    seturl(item.url);
    setnivel3(item.titlevideo);
  }

  
function nivelesacceso(a){
  // array para almacenar los nombres de los subniveles de acceso
  // recorremos la matriz de secciones
for (let i = 0; i < data.length; i++) {
const section = data[i].sections;
if (section) {
  // recorremos las secciones y sus videos
  for (let j = 0; j < section.length; j++) {
    const videos = section[j].videos;
    if (videos) {
      // recorremos los videos de la sección actual
      for (let k = 0; k < videos.length; k++) {
        const video = videos[k];
        // si encontramos el video con el título deseado
        if (video.titlevideo === a) {
          // almacenamos los nombres de los subniveles de acceso
         
          setnivel1(data[i].name);
          setnivel2(section[j].name);
          setdatasub(data[i].sections);
          setdatavideos(section[j].videos);
          setvideos(section[j].videos);
          // detenemos el bucle
          break;
        }
      }
    }
  }
 }
}
}


function nivel1view(){
  setshowcatalog(true);
  setshowsubcatalog(false);
  setshowvideos(false);
  setshowplay(false);
  setshowlis(false);
}
function nivel2view(){
  setshowcatalog(false);
  setshowsubcatalog(true);
  setshowvideos(false);
  setshowplay(false);
  setshowlis(false);
}
function nivel3view(){
  setshowcatalog(false);
  setshowsubcatalog(false);
  setshowvideos(true);
  setshowplay(false);
  setshowlis(false);
}



function nuevoarray(){
  const videosArray = data.reduce((accumulator, { sections }) => {
    const videos = sections?.flatMap(({ videos }) =>
      videos?.map(({ code, titlevideo, description, url, img }) => ({
        code,
        titlevideo,
        description,
        url,
        img,
      }))
    );
    return videos ? [...accumulator, ...videos] : accumulator;
  }, []).filter((video) => video);
  console.log(videosArray);
  setdatasearchvideos(videosArray);
}


const handleOnSelect5 = (e) => { 
  setSearchTerm(e.titlevideo);
  nivelesacceso(e.titlevideo);
  setshowcatalog(false);
  setshowsubcatalog(false);
  setshowvideos(false);
  setshowplay(true);
  setshowlis(false);
  seturl(e.url);
  setnivel3(e.titlevideo);

};
const handleOnFocus5 = () => {
};
const handleOnClear = () => {    
};
const handleOnSearch = (e) => {
 setSearchTerm(e);
 
};

function search(){
  
  console.log(searchTerm)
  setshowcatalog(false);
  setshowsubcatalog(false);
  setshowvideos(false);
  setshowplay(false);
  setshowlis(true);

var resultados = [];
// recorremos el arreglo y comparamos cada elemento con el valor del input
for (let i = 0; i < datasearchvideos.length; i++) {
  if (datasearchvideos[i].titlevideo.includes(searchTerm.toUpperCase())) {
    resultados.push(datasearchvideos[i]);
  }
}
setdatasv(resultados);
}




  return (
<>
  <div className='container-videos'>
    <h1 className='titlevid' style={showplay?{"display":"none"}:{"display":"block"}}>
      Capacitación
    </h1>

    <div className='grid-busqueda'>
    <div className='barra-busqueda'>
          <ReactSearchAutocomplete
              items={datasearchvideos.map((item, index) => ({ ...item, id: index }))}
              fuseOptions={{keys:["titlevideo"]}}
              resultStringKeyName="titlevideo"
              onSearch={handleOnSearch}
              onSelect={handleOnSelect5}
              onFocus={handleOnFocus5}
              onClear={handleOnClear}
              styling={{
                height: "40px",
                border: "1px solid gray",
                borderRadius: "4px",
                color: "black",
                iconColor: "black",
                lineColor: "black",
                placeholderColor: "black",
                zIndex: 10,
              }}
              showIcon={true}
              placeholder="Buscar Video Tutorial" 
            />        
    </div>
    <div className="button-seacrh">
       <button onClick={search}>Buscar</button>
    </div>
    </div>

    <div class="breadcrumbs-videos">
        <a onClick={()=>nivel1view()}>CÁTALOGO</a> 
        {
          showsubcatalog === true ?
          <>/<span> {nivel1}</span></>:
            showvideos === true ?
             <>
              /<a onClick={()=>nivel2view()}>{nivel1}</a>
              /<span>{nivel2}</span>
             </>:
             showplay ===  true ? 
             <>
              /<a onClick={()=>nivel2view()}>{nivel1}</a>
              /<a onClick={()=>nivel3view()}>{nivel2}</a>
              /<span>{nivel3}</span>
             </>:
        <></>
        }       
    </div>


      <div className='container-seccion-videos' style={showcatalog===true?{"display":"block"}:{"display":"none"}}>
      <div className='catalogo-videos'>
        <div className="grid-videos">
          {
            data.map((item, index)=>
              <div className="carta-vid" style={{ gridArea: `carta${index + 1}` }} key={index} onClick={()=>filtro1(item)}>
                  <div className="icon-container">{item.icon}</div>
                  <div className="descripcion-carta-vid">
                    <p className="title-carta-vid">{item.name}</p> 
                </div>
              </div> 
            )
          }
        </div>
      </div>
      </div>
    
      <div className='container-seccion-videos'  style={showsubcatalog===true?{"display":"block"}:{"display":"none"}}>
      <div className='catalogo-videos'>
        <div className="grid-videos">
          {
            datasub.map((item, index)=>
              <div className="carta-vid" style={{ gridArea: `carta${index + 1}` }} key={index} onClick={()=>filtro2(item)}>
                  {/* <img className="img-format" src={item.img} onClick={()=>vista(item.urlpdf)} alt=''></img> */}
                  <div className="icon-container">{item.icon}</div>
                  <div className="descripcion-carta-vid">
                    <p className="title-carta-vid">{item.name}</p> 
                </div>
              </div> 
            )
          }
        </div>
        </div>
      </div>

      <div className='container-seccion-videos'  style={showvideos===true?{"display":"block"}:{"display":"none"}}>
      <div className='catalogo-videos'>
        <div className="grid-videos-caratulas">
          {
            datavideos.map((item, index)=>
              <div className="carta-vid" style={{ gridArea: `carta${index + 1}` }} key={index} onClick={()=>filtro3(item, datavideos )}>
                  <img className="img-format" src={item.img} alt="caratulas"></img>
                  <div className="descripcion-carta-vid">
                    <p className="title-carta-vid">{item.titlevideo}</p> 
                </div>
              </div> 
            )
          }
        </div>
        </div>
      </div>
      <div className='container-search-vid' style={showlist===true?{"display":"block"}:{"display":"none"}}>
      <div className='catalogo-videos'>
        <div className="grid-videos-caratulas">
          {
            datasv.map((item, index)=>
              <div className="carta-vid" style={{ gridArea: `carta${index + 1}` }} key={index} onClick={()=>filtro4(item)}>
                  <img className="img-format" src={item.img} alt="caratulas"></img>
                  <div className="descripcion-carta-vid">
                    <p className="title-carta-vid">{item.titlevideo}</p> 
                </div>
              </div> 
            )
          }
        </div>
        </div>
      </div>
  
    {
      showplay?<Reproductor url={url} lista={videos} setnivel3={setnivel3}/>:<></>
    }
   </div>

   </>
  )
}
