import React from 'react'
import axios from "axios";

export const JIRA = () => {

    const issueData = {
        fields: {
          project: {
            id: 'ID_DEL_PROYECTO', // El ID del proyecto donde se creará la tarea
          },
          summary: 'Resumen de la tarea', // El título o resumen de la tarea
          description: 'Descripción detallada de la tarea', // La descripción detallada de la tarea
          issuetype: {
            id: 'ID_DEL_TIPO_DE_TAREA', // El ID del tipo de tarea (por ejemplo, ID para 'Task', 'Story', etc.)
          },
          assignee: {
            name: 'nombre_de_usuario', // El nombre de usuario del asignado a la tarea
          },
          // Otros campos personalizados, como "priority", "labels", "duedate", etc., si están configurados en tu instancia de Jira
        },
      };


      
const createJiraIssueInBoard = async () => {
    try {
      const projectId = 'your-project-id'; // Reemplaza 'your-project-id' con el ID del proyecto "Soportes"
      const response = await axios.post(`https://your-jira-instance/rest/api/2/issue`, {
        ...issueData,
        fields: {
          ...issueData.fields,
          project: {
            id: projectId,
          },
        },
      }, {
        headers: {
          Authorization: 'Basic ' + btoa('username:password'), // Reemplaza 'username' y 'password' con tus credenciales de Jira
          'Content-Type': 'application/json',
        },
      });
  
      console.log('Actividad creada en el tablero Soportes:', response.data);
      // Aquí puedes manejar la respuesta según tus necesidades
    } catch (error) {
      console.error('Error al crear la actividad:', error);
      // Aquí puedes manejar el error según tus necesidades
    }
  };


  const getProjectDetails = async () => {
  
    const username = 'israel.serrano@herramientasinformaticas.com';
    const token = 'ATATT3xFfGF0cDO7iDjbBpGjv38bpeJMW1whaeUyQ2Mhefhl4Iw77k2XrNaKSLrO9rSLByCF_NCC2VCmN46t1V0SmNWTspuGbKJhttACArTZJUTOBM4nJ7T1m4VU_SKfTgqjlWpXBV3IteaJc1Di3a4tOQ-BO--Uv2jJHzkrysG5scVmh3K8k3s=4AB4B5CB';
    const credentials = `${username}:${token}`;
    // Codifica las credenciales en base64
    const encodedCredentials = btoa(credentials);  
    try {
      const response = await axios.get(`https://eunice.atlassian.net/rest/api/2/project/SP`, {
        headers: {
        Authorization: 'Basic ' + encodedCredentials, // Reemplaza 'username' y 'password' con tus credenciales de Jira
        'Content-Type': 'application/json',
        },
      });
  
      console.log('Detalles del proyecto:', response.data);
      // Aquí puedes manejar la respuesta según tus necesidades
    } catch (error) {
      console.error('Error al obtener detalles del proyecto:', error);
      // Aquí puedes manejar el error según tus necesidades
    }
  };
 
  
  var data = {
    folio: "FC-123",
    asunto: "asunto pruebas",
    description: "esta es una descropcion general"
  }


  const handlejira = async()=>{

    const url = "https://tavmr1hck9.execute-api.us-east-1.amazonaws.com/prod/jira";
    try {
      const response = await axios.post(url, data, {
        headers: {
          // Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          // "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  return (
    <div>
        <button className='button' onClick={()=>handlejira()}>Jira</button>
        <br></br>
        <br></br>
    </div>
  )
}


