import "./formats.css";
//documentos
import format1 from "../../documents/formatos/Formato_curso.docx";
import format1p from "../../documents/formatos/Formato_curso.pdf";
import format2 from "../../documents/formatos/Formato_de_limpieza_baños.xlsx";
import format2p from "../../documents/formatos/Formato_de_limpieza_baños.pdf";
import downloadpdf from "../../imgcomponents/descargarpdf.jpg";
import downloadword from "../../imgcomponents/descargarword.png";
import downloadexcel from "../../imgcomponents/descargarexcel.png";
import sitio from "../../imgcomponents/sitio-en-construccion.png";
//imagenes
import carta1 from "../../imgcomponents/format1.png";
import carta2 from "../../imgcomponents/format2.png";
import AWS from 'aws-sdk';
import { useState } from "react";
import { UPLOADCSV } from "../../componets/uploadcsv";
import { JIRA } from "./jira";


export const Formats = () => {




    const handleformat = (text, archive) => {
        // Crea un enlace de descarga
        const link = document.createElement("a");
        // Establece el atributo href al archivo Word
        link.href = archive;
        // Establece el atributo download para que el archivo se descargue con un nombre específico
        link.download = `${text}`;
        // Haga clic en el enlace para descargar el archivo
        link.click();
    };     
      
  return (
    <div className="container-formats">  
      <div className="slider-formatos">
        <div className="capa-slider">
            <p className="text-slider">Formatos</p> 
            <p className="text-slider2">Fricongelados te da la bienvenida a nuestra web corporativa donde podrás encontrar 
            informacíon de los procedimientos de nuestra empresa...</p>
        </div>
      </div>

      <div className="catalogo-formats-container">
        <div className="scrumbead">
            Formatos: 
            {/* <UPLOADCSV></UPLOADCSV> */}

        </div>
        <div className="catalogo-formats">
            <div className="grid-formats">
                <div className="carta carta1">
                    <img className="img-format" src={carta1}></img>
                    <div className="descripcion-carta">
                    <p className="title-carta">FORMATO CURSO</p>
                    <img className="download-format" src={downloadword} onClick={(event)=>handleformat("formato curso", format1)}></img> 
                    <img className="download-format" src={downloadpdf} onClick={(event)=>handleformat("formato curso", format1p)}></img>
                    </div>
                </div>
                <div className="carta carta2">
                    <img className="img-format" src={carta2}></img>
                    <div className="descripcion-carta">
                    <p className="title-carta">FORMATO LIMPIEZA BAÑOS</p>
                    <img className="download-format" src={downloadexcel} onClick={(event)=>handleformat("Formato de limpieza baños", format2)}></img> 
                    <img className="download-format" src={downloadpdf} onClick={(event)=>handleformat("Formato de limpieza baños", format2p)}></img>
                    </div>
                </div>
            </div>
        </div>
        <div className="" style={{"textAlign":"center"}}>
        <JIRA></JIRA>
        </div>
      </div>
    </div>
  )
}
