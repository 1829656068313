import React, { useState } from 'react'
import { Storage, Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';

export const SUBIRS3 = () => {



  return (
    <>
    <div>
    </div>
    
    </>
    )
}
