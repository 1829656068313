import slider1 from "../imgcomponents/slider-1.png";
import "./home.css";
import congelador from "../imgcomponents/congelador.png";
import fc from "../imgcomponents/fricongelados.png";
import organi from "../imgcomponents/organigrama.png";
import { useEffect, useState } from "react";
import persona from "../imgcomponents/persona.png"

export const Home = () => {


  const[mision, setmision]=useState(true);
  const[vision, setvision]=useState(false);
  const[valores, setvalores]=useState(false);
  const [infoPos, setInfoPos] = useState({ left: 0, top: 0 });



  const handlemision=()=>{
    setmision(true);
    setvision(false);
    setvalores(false);
  }
  const handlevision=()=>{
    setmision(false);
    setvision(true);
    setvalores(false);
  }
  const handlevalores=()=>{
    setmision(false);
    setvision(false);
    setvalores(true);
  }

    useEffect(() => {
      const areas = document.querySelectorAll('area');
      const informaciones = document.querySelectorAll('.informacion');
     

      areas.forEach((area, index) => {
        area.addEventListener('mousemove', (event) => {
        
        setInfoPos({
            left: event.clientX-(localStorage.getItem("menu2Close")==="true"?300:100),
            top: event.clientY>=350?event.clientY-(event.clientY*0.4):event.clientY
          });
          informaciones[index].classList.add('active');
        });
  
        area.addEventListener('mouseleave', () => {
          informaciones[index].classList.remove('active');
        });
      });
    }, []);


  return (
    <>
    <div className="content-home">
      <div className="slider-home">
        <div className="capa-slider">
            <p className="text-slider">Bienvenido YORDI</p> 
            <p className="text-slider2">Fricongelados te da la bienvenida a nuestra web corporativa donde podrás encontrar 
            informacíon de los procedimientos de nuestra empresa...</p>
        </div>
      </div>

      <h1 className="quienes-somos">
            ¿QUIENÉS SOMOS?
            <div className="linea"></div>
      </h1>
      
      <div className="container-history"> 
      <div className="ident">
            <div className="identseccion">
              <p className="ident1">NUESTRA</p>
              <div className="linea2"></div>  
            </div>
            <p className="ident2">&nbsp;&nbsp;&nbsp;HISTORIA</p>
        </div>
          <p>
            Fricongelados Citlaltepetl S.A de C.V. nace en la ciudad de Orizaba Veracruz, en Agosto de 1999,
             concesión que nos brinda Unilever de México.
          </p>
      </div>
      <div className="container-valores">

        <div className="ident">
            <div className="identseccion">
              <p className="ident1">NUESTRA</p>
              <div className="linea2"></div>  
            </div>
            <p className="ident2">&nbsp;&nbsp;&nbsp;IDENTIDAD EMPRESARIAL</p>
        </div>

        <div className="container-mvv">
          <div className="lista-de-items">
              <ul>
                <li>
                  <p onClick={handlemision} className={mision===true?"list-select":"list-no-select"}>1. - Nuestra Misión</p>
                </li>
                <li>
                  <p onClick={handlevision} className={vision===true?"list-select":"list-no-select"}>2.- Nuestra Visión</p>
                </li>
                <li>
                  <p onClick={handlevalores} className={valores===true?"list-select":"list-no-select"}>3.- Nuestros Valores</p>
                </li>
              </ul>
          </div>
          <div className="contenido-items">
          
          <div className="mision" style={mision===true?{"display":"block"}:{"display":"none"}}>
          <p className="title-mision">Misión</p>
          <p className="texto-mvv">
            Ofrecer un servicio de excelencia en la distribución, a un bajo costo de operación, utilizando la más alta tecnología 
            de información, así como la infraestructura que asegura una inmejorable oportunidad de negocio a nuestros clientes.</p>
          <p className="texto-mvv">Dirigiendo nuestras acciones a la sustentabilidad creando conciencia ambiental.</p> 
          
        </div>
        <div className="vision" style={vision===true?{"display":"block"}:{"display":"none"}}>
          <p className="title-vision">Visión</p>
          <p className="texto-mvv">Ser una empresa líder en la distribución de productos de alta calidad, con el mejor servicio en nuestra zona
             de influencia, ofreciendo las mejores oportunidades de negocio a nuestros clientes y colaboradores, con el 
             fin de posicionarnos como una empresa eficiente y rentable.
          </p>
        </div>
        <div className="valores" style={valores===true?{"display":"block"}:{"display":"none"}}>
          <p className="title-valores">Valores</p>
          <p className="texto-mvv">
            <b>Compromiso:</b> Es la actitud positiva y verdadera que permita transformar las promesas en realidad con un mejoramiento constante de nuestras acciones aun en las circunstancias adversas.
          </p>
          <p className="texto-mvv">
            <b>Responsabilidad:</b> Realizar nuestras actividades en tiempo y forma sin afectar los intereses de los demás siendo conscientes de nuestros actos y sus consecuencias.
          </p>
          <p className="texto-mvv">
            <b>Honestidad:</b> Hablar y actuar con la verdad aun en situaciones contrarias.
          </p>
          <p className="texto-mvv">
            <b>Lealtad:</b> Hacer, crecer y cumplir correctamente nuestras actividades en todo momento.
          </p>
          <p className="texto-mvv">
            <b>Ética:</b> Actuar y comprometerse correctamente de acuerdo a los principios y reglas para mantener una armonía y felicidad.
          </p>
        </div>
          </div>
        </div>
      </div>

    <div className="container-organigrama">
        <div className="ident">
            <div className="identseccion">
              <p className="ident1">NUESTRO</p>
              <div className="linea2"></div>  
            </div>
            <p className="ident2">&nbsp;&nbsp;&nbsp;ORGANIGRAMA</p>
            <p className="text-orga">La estructura interna de Fricongelados Citlaltepetl S.A de C.V. es la siguiente:</p>
        </div>

        <div className="organigrama-img">
          <img src={organi} usemap="organimap"></img>
          <map name="organimap">
            <area shape="rect" 
              className="personal1"
              //coords="12%,10%,106,127" 
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal2"
              href="#informacion-1"
              alt=""
            />
             <area shape="rect" 
              className="personal3"
              href="#informacion-1"
              alt=""
            />
            <area shape="rect" 
              className="personal4"
              href="#informacion-1"
              alt=""
            />
            <area shape="rect" 
              className="personal5"
              href="#informacion-1"
              alt=""
            />
            <area shape="rect" 
              className="personal6"
              href="#informacion-1"
              alt=""
            />
            <area shape="rect" 
              className="personal7"
              href="#informacion-1"
              alt=""
            />
             <area shape="rect" 
              className="personal8"
              href="#informacion-1"
              alt=""
            />
             <area shape="rect" 
              className="personal9"
              href="#informacion-1"
              alt=""
            />
             <area shape="rect" 
              className="personal10"
              href="#informacion-1"
              alt=""
            />
            {/* preventas*/}
            <area shape="rect" 
              className="personal11"
              href="#informacion-1"
            />
            
            <area shape="rect" 
              className="personal12"
              href="#informacion-1"
            />
            
            <area shape="rect" 
              className="personal13"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal14"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal15"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal16"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal17"
              href="#informacion-1"
            />

          {/* rutas */}
            <area shape="rect" 
              className="personal21"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal22"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal23"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal24"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal25"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal26"
              href="#informacion-1"
            />
            <area shape="rect" 
              className="personal27"
              href="#informacion-1"
            />
          </map>

        <div id="informacion-1" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
        <   img src={persona}></img>
            <h2>Administración</h2>
            <p>Su funcion es: </p>
        </div>
        
        <div id="informacion-2" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Administradora</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-3" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Contadora</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-4" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Camarista</h2>
            <p>Su funcion es:</p>
        </div>
        
        <div id="informacion-5" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Técnico</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-6" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ayudante</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-7" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Vigilante</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-8" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ventas</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-9" className="informacion" style={{ left: infoPos.left, top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Coordinador 1</h2>
            <p>Su funcion es:</p>
        </div>

        <div id="informacion-10" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Cordinador 2</h2>
            <p>Su funcion es:</p>
        </div>

        {/* preventa */}

        <div id="informacion-11" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 1</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-12" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 2</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-13" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 3</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-14" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 4</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-15" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 5</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-16" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 6</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-17" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Preventa 7</h2>
            <p>Su funcion es:</p>
        </div>
        {/* rutas */}
        <div id="informacion-21" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 1</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-22" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 2</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-23" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 3</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-24" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 4</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-25" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 5</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-26" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 6</h2>
            <p>Su funcion es:</p>
        </div>
        <div id="informacion-27" className="informacion" style={{ left: infoPos.left-(localStorage.getItem("menu2Close")==="true"?+250:300), top: infoPos.top }}>
            <img src={persona}></img>
            <h2>Ruta 7</h2>
            <p>Su funcion es:</p>
        </div>
        
        


        </div>

         
        
       

        </div> 
      <div className="footer">
        <p>Fricongelados</p>
      </div>
      </div>
    </>
  )
}
