import React, { useEffect, useState } from 'react'
import "./alertas.css";


    export const Alertas = (props) => {


    const {view, tipoalert, mensaje, actualizarEstado} = props;
 
    useEffect(() => {
        if(view===true){
            const timer = setTimeout(() => {
                actualizarEstado({view: false});
            }, 5000);
            return () => clearTimeout(timer);
        } 
    }, [view, actualizarEstado]);
    
    function handlecerrar(){
      actualizarEstado({view: false})
    }

    return (
        <>
        {
            view && (
            <p 
                className={tipoalert===1?"alert alert-danger":"alert alert-success"} onClick={handlecerrar}> 
                {mensaje}
            </p>
            )
        }
        </>
    )
    }

