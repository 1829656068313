
import imagen1 from "../../imgcomponents/procedimientos/img1.png";
import imagen2 from "../../imgcomponents/procedimientos/img2.png";
import imagen3 from "../../imgcomponents/procedimientos/img3.jpg";
import imagen4 from "../../imgcomponents/procedimientos/img4.jpg";
import imagen5 from "../../imgcomponents/procedimientos/img5.jpg";
import imagen6 from "../../imgcomponents/procedimientos/img6.jpg";
import imagen7 from "../../imgcomponents/procedimientos/img7.jpg";
import imagen8 from "../../imgcomponents/procedimientos/img8.jpg";
import imagen9 from "../../imgcomponents/procedimientos/img9.jpg";
import imagen10 from "../../imgcomponents/procedimientos/img10.jpg";

export const documents = [
    {   
        id: 1,
        title: 'CAPACITACIÓN DE PERSONAL DE NUEVO INGRESO', 
        description: 'CAPACITACIÓN DE PERSONAL DE NUEVO INGRESO', 
        img: imagen1,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/Capacitaci%C3%B3n+de+personal+de+nuevo+ingreso.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/Capacitaci%C3%B3n+de+personal+de+nuevo+ingreso.pdf'
    },
    {
        id: 2,
        title: 'CÓDIGO DE CONDUCTA FRICONGELADOS', 
        description: 'CÓDIGO DE CONDUCTA FRICONGELADOS', 
        img: imagen2,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/CODIGO+DE+CONDUCTA+FRICONGELADOS.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/CODIGO+DE+CONDUCTA+FRICONGELADOS.pdf'
    },
    {
        id: 3,
        title: 'CONTRATO INDIVIDUAL DE TRABAJO INDETERMINADO', 
        description: 'CONTRATO INDIVIDUAL DE TRABAJO INDETERMINADO', 
        img: imagen3,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/CONTRATO+INDIVIDUAL+DE+TRABAJO+INDETERMINADO..docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/CONTRATO+INDIVIDUAL+DE+TRABAJO+INDETERMINADO..docx.pdf'
    },
    {
        id: 4,
        title: 'DESTRUCCIÓN DEL PRODUCTO FUERA DE ESPECIFICACIONES', 
        description: 'DESTRUCCIÓN DEL PRODUCTO FUERA DE ESPECIFICACIONES', 
        img: imagen4,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/DESTRUCCI%C3%93N+DEL+PRODUCTO+FUERA+DE+ESPECIFICACIONES.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/DESTRUCCI%C3%93N+DEL+PRODUCTO+FUERA+DE+ESPECIFICACIONES.pdf'
    },
    {
        id: 5,
        title: 'MONITOREO DE TEMPERATURA DE LA CÁMARA DE CONGELACIÓN', 
        description: 'MONITOREO DE TEMPERATURA DE LA CÁMARA DE CONGELACIÓN', 
        img: imagen5,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/MONITOREO+DE+TEMPERATURA+DE+LA+C%C3%81MARA+DE+CONGELACI%C3%93N.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/MONITOREO+DE+TEMPERATURA+DE+LA+C%C3%81MARA+DE+CONGELACI%C3%93N.pdf'
    },
    {
        id: 6,
        title: 'PLAN DE CONTINGENCIA ELECTRICA', 
        description: 'PLAN DE CONTINGENCIA ELECTRICA', 
        img: imagen6,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/Plan+de+contingencia+electrica.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/Plan+de+contingencia+electrica.pdf'
    },
    {
        id: 7,
        title: 'POLITICA DE PREVENCIOìN DE RIESGOS PSICOSOCIALES FRICONGELADOS', 
        description: 'POLìTICA DE PREVENCIOìN DE RIESGOS PSICOSOCIALES FRICONGELADOS', 
        img: imagen7,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/POLI%C3%ACTICA+DE+PREVENCIO%C3%ACN+DE+RIESGOS+PSICOSOCIALES+FRICONGELADOS.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/POLI%C3%ACTICA+DE+PREVENCIO%C3%ACN+DE+RIESGOS+PSICOSOCIALES+FRICONGELADOS.pdf'
    },
    {
        id: 8,
        title: 'POLÍTICA DE SALUD, MEDIO AMBIENTE, CALIDAD Y SEGURIDAD DEL CONSUMIDOR', 
        description: 'POLÍTICA DE SALUD, MEDIO AMBIENTE, CALIDAD Y SEGURIDAD DEL CONSUMIDOR', 
        img: imagen8,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/POL%C3%8DTICA+DE+SALUD%2C+MEDIO+AMBIENTE%2C+CALIDAD+Y+SEGURIDAD+DEL+CONSUMIDOR.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/POL%C3%8DTICA+DE+SALUD%2C+MEDIO+AMBIENTE%2C+CALIDAD+Y+SEGURIDAD+DEL+CONSUMIDOR.pdf'
    },
    {
        id: 9,
        title: 'POLÍTICA DE VIDRIO (GLASS POLICY)', 
        description: 'POLÍTICA DE VIDRIO (GLASS POLICY)', 
        img: imagen9,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/POL%C3%8DTICA+DE+VIDRIO+(GLASS+POLICY).docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/POL%C3%8DTICA+DE+VIDRIO+(GLASS+POLICY).pdf'
    },
    {
        id: 10,
        title: 'PUESTA EN MARCHA DESPUÉS DE UNA DESCONGELACIÓN NORMAL', 
        description: 'PUESTA EN MARCHA DESPUÉS DE UNA DESCONGELACIÓN NORMAL', 
        img: imagen10,
        urldoc: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/PUESTA+EN+MARCHA+DESPU%C3%89S+DE+UNA+DESCONGELACI%C3%93N+NORMAL.docx', 
        urlpdf: 'https://web-corporativa-documents.s3.amazonaws.com/procedimientos/PUESTA+EN+MARCHA+DESPU%C3%89S+DE+UNA+DESCONGELACI%C3%93N+NORMAL.pdf'
    },
]   