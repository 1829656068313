import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HailIcon from '@mui/icons-material/Hail';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BuildIcon from '@mui/icons-material/Build';
import Groups2Icon from '@mui/icons-material/Groups2';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';


//subcatalogo
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';

import PercentIcon from '@mui/icons-material/Percent';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import StoreIcon from '@mui/icons-material/Store';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PublicIcon from '@mui/icons-material/Public';
import TrafficIcon from '@mui/icons-material/Traffic';
import SummarizeIcon from '@mui/icons-material/Summarize';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import GavelIcon from '@mui/icons-material/Gavel';
import BallotIcon from '@mui/icons-material/Ballot';
import FlightIcon from '@mui/icons-material/Flight';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import RequestPageIcon from '@mui/icons-material/RequestPage';
//imagenes
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForkLeftIcon from '@mui/icons-material/ForkLeft';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import CreditScoreIcon from '@mui/icons-material/CreditScore';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import FeedIcon from '@mui/icons-material/Feed';
import FactCheckIcon from '@mui/icons-material/FactCheck';
export const data = [
    {
        id: 101,
        name: 'ACTIVOS',
        icon: <AttachMoneyIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
            {
              name: 'MARCAS Y MODELOS',
              icon: <BrandingWatermarkIcon className='icon' style={{color: "white"}}/>,
              permision: [' '],
              videos: [
                    {
                      code: 154555,
                      titlevideo: "CREAR MARCA", 
                      description: 'En este video se mostrará paso a paso el como se puede crear una marca', 
                      url: 'https://hisoporte.s3.amazonaws.com/videos/od2/Fricongelados/cambiar_Contrase%C3%B1a_Proveedor.mp4', 
                      img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/caratula1.PNG',
                    },
                    {
                      code: 154556,
                      titlevideo: "EDITAR MARCA", 
                      description: ' En este video encontrarás una guía paso a paso para poder modificar una marca',
                      url: '', 
                      img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/caratula2.PNG',
                    },
                    {
                      code: 154557,
                      titlevideo: "ELIMINAR MARCA", 
                      description: ' En este video encontrarás una guía paso a paso para poder modificar una marca',
                      url: '', 
                      img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/caratula3.PNG',
                    },
                    {
                      code: 154558,
                      titlevideo: "VER MÁS DETALLES", 
                      description: ' En este video encontrarás una guía paso a paso para poder modificar una marca',
                      url: '', 
                      img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/caratula4.PNG',
                    }
              ],
            },  
            {
              name: 'VEHÍCULOS',
              permision: [' '],
              icon: <CarCrashIcon className='icon' style={{color: "white"}}/>,
              videos: [
                    {
                      code: 154559,
                      titlevideo: "CREAR VEHÍCULO", 
                      description: 'En este video encontrarás una guía paso a paso para poder crear un nuevo vehìculo.', 
                      url: '', 
                      image: '',
                    },
                    {
                      code: 154560,
                      titlevideo: "MODIFICAR VEHÌCULO", 
                      description: ' En este video encontrarás una guía paso a paso para poder modificar un vehículo',
                      url: '', 
                      image: '',
                    },
                    {
                      code: 154561,
                      titlevideo: "ELIMINAR VEHÌCULO", 
                      description: ' En este video encontrarás una guía paso a paso para poder eliminar un vehículo',
                      url: '', 
                      image: '',
                    },
              ],
            }
        ]
    },
    {
        id: 102,
        name: 'BANCOS',
        icon: <AccountBalanceIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: 'CORTE DE CAJA',
            icon: <AccountBalanceWalletIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          }
        ]
    },
    {
        id: 103,
        name: 'COLABORADORES',
        icon: <HailIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: 'EMPLEADOS',
            icon: <EngineeringIcon className='icon' style={{color: "white"}}/>,
            permision: [' ']
          },  
          {
            name: 'CONDUCTORES',
            permision: [' '],
            icon: <AirlineSeatReclineNormalIcon className='icon' style={{color: "white"}}/>,
          }
        ]
    },
    {
        id: 104,
        name: 'FINANZAS',
        icon: <PriceChangeIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: 'CUENTAS CONTABLES',
            icon: <PointOfSaleIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
        ]
    },
    {
        id: 105,
        name: 'REPORTES',
        icon: <AssessmentIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: 'DETALLES DE VENTAS',
            icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'MAPA DE RUTAS',
            icon: <PublicIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REPORTE DE VENTAS POR RUTA',
            icon: <TrendingDownIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REPORTE DE VENTAS EN TIEMPO REAL',
            icon: <PendingActionsIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REPORTE SUMARIA ANUAL',
            icon: <SummarizeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'SEMÁFORO',
            icon: <TrafficIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
        ]
    },
   
    {
        id: 106,
        name: 'COMPRAS',
        icon: <ShoppingCartIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: 'CONSULTAR DOCUMENTOS ENVÍADOS',
            icon: <ContentPasteSearchIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'ENVÍO DE DOCUMENTOS',
            icon: <UploadFileIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'CATALOGO DE GASTOS',
            icon: <BorderAllIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REGLA DE GASTOS',
            icon: <GavelIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'GASTOS NO DEDUCIBLES',
            icon: <BallotIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'GASTOS DEL EXTRANJERO',
            icon: <FlightIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REPORTE DE GASTOS',
            icon: <ReceiptLongIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REPORTE DE GASTOS AGRUPADOS',
            icon: <RecentActorsIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'POLIZAS',
            icon: <RequestPageIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
        ]
    },
    {
        id: 107,
        name: 'FACTURAS',
        icon: <LibraryBooksIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: "CONSULTAR CONCEPTOS",
            icon: <CreditScoreIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "CONSULTAR FACTURAS",
            icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "CONSULTAR FACTURAS PPD",
            icon: <RequestQuoteIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "CONSULTAR IMPUESTOS",
            icon: <MoneyOffIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "REALIZAR CARTA PORTE",
            icon: <ContentPasteGoIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "REALIZAR COMPLEMENTO DE PAGO",
            icon: <CurrencyExchangeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "REALIZAR FACTURA",
            icon: <FeedIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
        ]
    },
    {
        id: 108,
        name: 'SOCIOS DE NEGOCIOS',
        icon: <Groups2Icon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: 'DESCUENTOS',
            icon: <PercentIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
            videos: [
              {
                code: 555522,
                titlevideo: "CONSULTAR DESCUENTOS", 
                description: 'En este video ', 
                url: 'https://hisoporte.s3.amazonaws.com/videos/od2/Fricongelados/Socios+de+Negocios/Descuentos/consultar_Descuento.mp4', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/consultar+descuentos.png',
              },
              {
                code: 555523,
                titlevideo: "REGISTRAR DESCUENTO", 
                description: 'En este video ', 
                url: 'https://hisoporte.s3.amazonaws.com/videos/od2/Fricongelados/Socios+de+Negocios/Descuentos/registrar_Descuento.mp4', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/agregar_descuento.png',
              },
              {
                code: 555524,
                titlevideo: "EDITAR DESCUENTO", 
                description: 'En este video ', 
                url: 'https://hisoporte.s3.amazonaws.com/videos/od2/Fricongelados/Socios+de+Negocios/Descuentos/editar_Descuento.mp4', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/editar+descuento.png',
              },
              {
                code: 555525,
                titlevideo: "ELIMINAR DESCUENTO", 
                description: 'En este video', 
                url: 'https://hisoporte.s3.amazonaws.com/videos/od2/Fricongelados/Socios+de+Negocios/Descuentos/eliminar_Descuento.mp4', 
                img: 'https://web-corporativa-documents.s3.amazonaws.com/caratulas/eliminar+descuento.png',
              },
            ]
          },
          {
            name: 'PROMOCIONES',
            icon: <LoyaltyIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'PUNTOS DE VENTA',
            icon: <StoreIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'SECTORES',
            icon: <HomeWorkIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'SOCIO DE NEGOCIOS',
            icon: <HailIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'RECUPERAR SOCIO DE NEGOCIOS',
            icon: <ReduceCapacityIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'REGISTRO GENERAL',
            icon: <SwitchAccountIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: 'PUNTOS DE VENTA SIN SECTOR',
            icon: <AddBusinessIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          }
        ]
    },
    {
        id: 109,
        name: 'OPERACIONES CON UNILEVER',
        icon: <AssignmentIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: "REPORTE DE BONIFICACIONES",
            icon: <FactCheckIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
        ]
    },
    {
        id: 110,
        name: 'CONFIGURACIÓN',
        icon: <BuildIcon className='icon' style={{color: "white"}}/>,
        restriccion:"",
        sections: [
          {
            name: "ALMACÉN",
            icon: <WarehouseIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "EMPRESA",
            icon: <ApartmentIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "ROLES",
            icon: <AccountTreeIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "RUTAS",
            icon: <ForkLeftIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
          {
            name: "USUARIOS",
            icon: <PersonSearchIcon className='icon' style={{color: "white"}}/>,
            permision: [' '],
          },
        ]
    }
]