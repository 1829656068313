import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import fc from "../imgcomponents/fricongelados.png";
import fondo from "../imgcomponents/fondo-login.jpg";


export class ForgotPassword extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      Username : '',
    }

    this.forgotPassword = this.forgotPassword.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
  }

  async resendConfirmationCode(){
    try {
      await Auth.resendSignUp(this.state.Username);
      // console.log('code resent successfully');
    } catch (err) {
        // console.log('error resending code: ', err);
    }
  }

  handleFormSubmission(evt) {
    evt.preventDefault();
    this.forgotPassword();
  }

  async forgotPassword() {
    const username = this.state.Username;

    try{
      await Auth.forgotPassword(username)
      this.props.SetUserName(username)
      this.props.SetAuthState(AuthState.ResetPassword)
    } catch(err){
      // console.log(err)
    }
  }

  render() {
    return (
      <div className='container_login'>
          <div className='grid-login'>
            <div className='formsesion'>
              <div className='logoempresa'>
                  <img src={fc}></img>
              </div>
                
              <div className='camplogin'>
              
              < div className="div-lg4">
                <p className='bienvenido'>Cambiar Contraseña</p>
              </div>
              <div className='grid-form-login'>
              <div className='form-user'>
                <label className='label-usuario'>Usuario</label>
                <input 
                  type={"text"}
                  className="input-usuario"
                  onChange={(e) =>
                    this.setState({Username: e.target.value})  
                  }  
                  placeholder="Escribe tu usuario"
                ></input>
                </div>
                <div className=''>
                    <button className='boton-login' 
                        onClick={this.handleFormSubmission}>
                      Enviar Código
                    </button> 
                    <button className='boton-cancelar' 
                        onClick={()=>this.props.SetAuthState(AuthState.SignIn)}>
                      Cancelar
                    </button> 
                </div>                
              </div>
            </div>
            </div>
            <div className='imglogin'>
              <img src={fondo}></img>
            </div>
          </div>
        </div>
    )
  }
}

export default ForgotPassword