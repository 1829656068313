import React, { useEffect, useState } from 'react'
import "./procedures.css";
import {documents} from "./data";
import downloadpdf from "../../imgcomponents/descargarpdf.jpg";
import downloadword from "../../imgcomponents/descargarword.png";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import AWS from 'aws-sdk';

export const Procedures = () => {
    
const [data, setdata]=useState(documents);
const [show, setshow]=useState(false);


const handleOnSearch = (string) => {
  console.log("search")
};
const handleOnSelect5 = (item) => {
   setdata(data.filter(i=>i.title===item.title))
};
const handleOnFocus5 = () => {
  console.log("focus");
};
const handleOnClear = () => {
  setdata(documents);
};

const [pdfDoc, setPdfDoc] = useState(null);

const handlePdfLoad = (event) => {
  console.log(`PDF loaded: ${event.target.src}`);
};
const vista = (item) =>{
  setshow(true);
  setPdfDoc(item);
}

console.log(documents);
  return (
    <div className='container-procedures'>
        <div className="slider-procedimientos">
        <div className="capa-slider">
            <p className="text-slider">Procedimientos</p> 
            <p className="text-slider2">Fricongelados te da la bienvenida a nuestra web corporativa donde podrás encontrar 
            informacíon de los procedimientos de nuestra empresa...</p>
        </div>
      </div>

  {
    show===false? 
    <>
    <div className='container-procedures-search'>
        <ReactSearchAutocomplete
                      items={documents}
                      fuseOptions={{ keys: ["id","title"]}}
                      resultStringKeyName="title"
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelect5}
                      onFocus={handleOnFocus5}
                      onClear={handleOnClear}
                      styling={{
                        height: "40px",
                        border: "1px solid gray",
                        borderRadius: "4px",
                        color: "black",
                        iconColor: "black",
                        lineColor: "black",
                        placeholderColor: "black",
                        zIndex: 10,
                      }}
                      showIcon={true}
                      placeholder="Buscar procedimiento"      
                    /> 
      </div>
      <div className='container-carts-procedures'>
      <div className="catalogo-procedures">
            <div className="grid-procedures">
                {
                    data.map((item, index)=>(
                        <div className="carta" style={{ gridArea: `carta${index + 1}` }} key={index}>
                            <img className="img-format" src={item.img} onClick={()=>vista(item.urlpdf)} alt=''></img>
                            <div className="descripcion-carta">
                             <p className="title-carta">{item.title}</p>
                             <a href={item.urldoc} download>
                             <img className="download-format" src={downloadword} alt=''></img> 
                            </a> 
                            <a href={item.urlpdf} download>
                             <img className="download-format" src={downloadpdf} alt=''></img> 
                            </a> 
                            </div>
                        </div>
                    ))
                }
                
            </div>
        </div> 
      </div>
    </>
    :
    <>
      <div className='container-show-document'>
        <div className='button-document'>
              <button onClick={()=>setshow(false)}>
                Salir
              </button>
        </div>
        <div className='pdf-document'>
       
        <embed
          src={pdfDoc}
          type="application/pdf"
          width="100%"
          height="600px"
          onLoad={handlePdfLoad}
      />
       
        </div>
      </div>
   
    </>
  }
    </div>
  )
}
