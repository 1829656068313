import React from 'react'
import "./navbar.css";

export const Navbar = (props) => {

    console.log(props.props.attributes.name);


  return (
    <div className='nav-cont'>
    <div className='navbar-content'>
      <div className='grid-nav'>
        <div className='nav1'>
          <li className="search-box">
            <i className="bx bx-search icon"></i>
            <input type="search" placeholder="Buscar...">
            </input>
          </li>
        </div>
        <div className='nav2'>
            <i className="bx bx-user icon"></i>   
            <p className='correo-user'>
              {props.props.attributes.name}
            </p>
        </div>

      </div>
       
    </div>    
    </div>
    
  )
}
