import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import fondo from "../imgcomponents/fondo-login.jpg";

export class ResetPassword extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       Username : '',
       Password : '',
       Code : '',
       RePassword : '', 
    }

    this.resetPassword = this.resetPassword.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleShowPasswordClick = this.handleShowPasswordClick.bind(this);
    this.handleShowPasswordClick2 = this.handleShowPasswordClick2.bind(this);
 
  }

  handleShowPasswordClick() {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  }
  handleShowPasswordClick2() {
    this.setState((prevState) => ({
      showPassword2: !prevState.showPassword2,
    }));
  }


  handleFormSubmission(evt) {
    if(this.state.Password === this.state.RePassword && this.state.Password != ''){
      if(this.state.Code!=String){
      evt.preventDefault();
      this.resetPassword();
      }else{
      }
    } else{
      alert("Las contraseñas no son iguales")
    } 
  }
  async resetPassword() {
    const username = this.props.SignUpUsername.trim();
    const code = this.state.Code.trim();
    const password = this.state.Password.trim();

    try{
      await Auth.forgotPasswordSubmit(username, code, password)
      this.props.SetAuthState(AuthState.SignIn)
    } catch(err){
      console.log(err);
      var errorMen= err;
      const errorMenString = errorMen?.toString();

      const splitString = errorMenString?.split('.');
      console.log(splitString);
      const truncatedString = splitString[0];
      console.log(truncatedString);

      if(truncatedString==="CodeMismatchException: Invalid verification code provided, please try again"){
        alert("Error: se proporcionó un código de verificación no válido, inténtelo de nuevo.")
      }else if(truncatedString==="LimitExceededException: Attempt limit exceeded, please try after some time"){
        alert("Error: Se excedió el límite de intentos, intente después de un tiempo (1hr) o comuníquese con soporte soporte@herramientasinformaticas.com.");
      }
      
      else{
        alert(err)
      }

    }
  }
   
  render() {
    const { showPassword } = this.state;
    const { showPassword2} = this.state;
     
    return (
      <div className='container_login'>
      <div className='grid-login'>
        <div className='formsesion'>
          <div className='camplogin'>
          < div className="div-lg5">
            <p className='bienvenido'>Nueva Contraseña</p>
          </div>
            
          <div className='grid-form-login'>
          <div className='form-register-bloq'>
            <label className='label-usuario'>Nombre de usuario</label>
            <input 
              type={"text"}
              className="input-usuario"
              placeholder={this.props.SignUpUsername} disabled
            ></input>
          </div>
          <div className='form-reset-pass'>
            <label className='label-usuario'>Código de Verificación</label>
            <input 
              type={"text"}
              className="input-usuario"
              onChange={(e)=>
                this.setState({Code: e.target.value})
              }
              placeholder="Escribe el código de Verificación"
            ></input>
          </div>
          <div className='form-pass'>
              <div className='pass1grid'>
             
              <label className='label-contraseña'>Contraseña</label>
              <input
                  type={showPassword ? "text" : "password"}
                  //value={password}
                  placeholder="Contraseña"
                  onChange={(e) =>
                    this.setState({ Password: e.target.value })
                  }

                />
              </div>
              <div className='pass2grid'>
              {showPassword ? (
                  <IconButton onClick={this.handleShowPasswordClick}>
                    <VisibilityIcon style={{ color: "#fff" }}/>
                  </IconButton>
                ) : (
                  <IconButton onClick={this.handleShowPasswordClick}>
                    <VisibilityOffIcon style={{ color: "#fff" }}/>
                  </IconButton>
                )}
              </div> 
            </div>
            <div className='form-pass'>
              <div className='pass1grid'>
             
              <label className='label-contraseña'>Confirmar Contraseña</label>
              <input
                  type={showPassword2 ? "text" : "password"}
                  //value={password}
                  placeholder="Contraseña"
                  onChange={(e) =>
                    this.setState({ RePassword: e.target.value })
                  }

                />
              </div>
              <div className='pass2grid'>
              {showPassword2 ? (
                  <IconButton onClick={this.handleShowPasswordClick2}>
                    <VisibilityIcon style={{ color: "#fff" }}/>
                  </IconButton>
                ) : (
                  <IconButton onClick={this.handleShowPasswordClick2}>
                    <VisibilityOffIcon style={{ color: "#fff" }}/>
                  </IconButton>
                )}
              </div> 
            </div>
            
            
            
            <div className=''>
                <button className='boton-login'  
                  onClick={this.handleFormSubmission}
                >
                  Cambiar Contraseña
                </button>
                <button className='boton-cancelar' 
                  onClick={()=>this.props.SetAuthState(AuthState.SignIn)}>
                  Cancelar
                </button> 
              </div>
          </div>
            
          </div>
        </div>
        <div className='imglogin'>
          <img src={fondo}></img>
        </div>
      </div>
    </div>
      
    )
  }
}

export default ResetPassword