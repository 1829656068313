import { useEffect, useState } from "react";
import fc from "../imgcomponents/fricongelados.png";
import "./menu2.css";
import { AuthState } from "@aws-amplify/ui-components";

export const Menu2 = (props) => {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const[close, setclose]=useState(screenWidth<=720?false:localStorage.getItem("menu2Close") === "true" || localStorage.getItem("menu2Close") === true ? true : false);
  const[dark, setDark]=useState(localStorage.getItem("temadark") === "true" || localStorage.getItem("temadark") === true ? true : false);
  const [body, setBody] = useState(document.querySelector('body'));



  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  

useEffect(() => {
  if(body.classList.contains("dark")){
  }else{
    if(dark===false){
      body.classList.toggle("dark");
    }
  }
},[])

useEffect(() => {
  localStorage.setItem("menu2Close", close);
}, [close]);

const handleLogout = () => {
  if(body.classList.contains("dark")){
    body.classList.toggle("dark");
  }
  localStorage.setItem("menu2Close", false);
  localStorage.setItem("temadark", false);
  props.SetAuthState(AuthState.SignedOut);
}; 

const handledark=()=>{

  console.log(screenWidth);

  if(dark===true){
    body.classList.toggle("dark");
    localStorage.setItem("temadark", false);
    setDark(false);
  }else{
    body.classList.toggle("dark");
    localStorage.setItem("temadark", true);
    setDark(true);
  }
}

  return (
    <>
     <nav className={close===true?"sidebar show":"sidebar close"}>
      <header>
        <div className="image-text">
          <span className="image">
            <img src={fc}>
            </img>
          </span>
          <div className="text logo-text">
            <span className="name">Web corporativa</span>
            <span className="profession">Fricongelados</span>
          </div>
        </div>

          <i className="bx bx-chevron-right toggle" onClick={(e)=>setclose(!close)}></i>
      </header>

      <div className="menu-bar">
        <div className="menu">
          <li className="search-box" style={{"display":"none"}}>
            <i className="bx bx-search icon"></i>
            <input type="search" placeholder="Buscar...">
            </input>
          </li>
          <br></br>
          <ul className="menu-links">
           <li className="nav-link">
              <a href="/">
                <i className="bx bx-home-alt icon"></i>
                <span className="text nav-text">Inicio</span>
              </a>
            </li> 
            <li className="nav-link">
              <a href="/procedures">
                <i className="bx bx-bar-chart-alt-2 icon"></i>
                <span className="text nav-text"> Procedimientos</span>
              </a>
            </li> 
            <li className="nav-link">
              <a href="training">
                <i className="bx bx-movie-play icon"></i>
                <span className="text nav-text">Manuales </span>
              </a>
            </li> 
            <li className="nav-link">
              <a href="/formats">
                <i className="bx bx-paste icon"></i>
                <span className="text nav-text">Formatos</span>
              </a>
            </li> 
           
          </ul>
        </div>

        <div className="bottom-content">
             <li className="">
              <a href="#">
                <i className="bx bx-log-out icon" onClick={handleLogout}></i>
                <span className="text nav-text" 
                onClick={handleLogout}>Cerrar Sesión</span>
              </a>
            </li> 
            <li class="mode">
                    <div class="sun-moon">
                        <i class='bx bx-sun icon moon'></i>   
                        <i class='bx bx-moon icon sun'></i>
                        
                    </div>
                    <span class="mode-text text">Modo Oscuro</span>

                    <div class="toggle-switch" onClick={handledark}>
                        <span class="switch"></span>
                    </div>
                </li>
         </div>

      </div>
     </nav>
    </>
  )
}
